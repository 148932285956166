import React, { useEffect, useState } from 'react';

import { useNavigate, useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, InputGroup, InputGroupText, Input
} from 'reactstrap';

import { FaFilePdf, FaEdit, FaClipboardList, FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from 'react-icons/md';

const MachineList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterPartData, setFilterPartData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [oilColumns, setOilColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);

  const { machine_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchColumns();
    fetchData();
  }, []);

  const fetchColumns = async () => {
    const response = await fetch(`${mainurl}regular_maintenance_components`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ machine_id: machine_id }),
    });
    const data = await response.json();

    if (data && data.data) {
      const { oil, filter } = data.data.components;

      const oilColumns = [
        {
          name: 'Milestone',
          selector: row => row.milestone,
          wrap: true,
        },
        ...oil.map((oilComponent, index) => ({
          name: oilComponent.component,
          selector: row => row[`oil_${index}`],
          wrap: true,
        }))
      ];

      const filterColumns = [
        {
          name: 'Milestone',
          selector: row => row.milestone,
          wrap: true,
        },
        ...filter.map((filterComponent, index) => ({
          name: filterComponent.component,
          selector: row => row[`filter_${index}`],
          wrap: true,
        }))
      ];

      setOilColumns(oilColumns);
      setFilterColumns(filterColumns);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${mainurl}regular_maintenance`, {
        machine_id: machine_id
      });

      if (response.data && response.data.data) {
        const { Oil, Filter } = response.data.data.component_type;

        // Flatten the data for Oil
        const oilData = Object.keys(Oil).map(milestone => ({
          milestone: milestone,
          ...Oil[milestone].reduce((acc, curr, index) => ({
            ...acc,
            [`oil_${index}`]: curr.servicing_component
          }), {})
        }));

        // Flatten the data for Filter
        const filterData = Object.keys(Filter).map(milestone => ({
          milestone: milestone,
          ...Filter[milestone].reduce((acc, curr, index) => ({
            ...acc,
            [`filter_${index}`]: curr.servicing_component
          }), {})
        }));

        setTableData(oilData);
        setFilterPartData(filterData);
      }
    } catch (error) {
      console.error("Error fetching maintenance data", error);
    } finally {
      setLoading(false);
    }
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = tableData.filter(item => {
      return item?.milestone?.toLowerCase().includes(query);
    });
    setTableData(filteredData);
  };


  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={9} className='mb-1'>
            <Title pageTitle="Maintainence List" />
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Title pageTitle="OIL" />
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={oilColumns}
                  data={tableData}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Title pageTitle="FILTER" />
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={filterColumns}
                  data={filterPartData}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>

      <Footer />
    </div>
  )
}

export default MachineList;