import React from 'react';

import {
    Row,
    Col,
} from 'reactstrap';

const Footer = () => {
    return (
            <Row className='p-1'>
                <Col xs="12" className='text-center text-muted'>
                    <div className=''>&copy; All Rights Reserved by Classic Engicon Private Limited</div>
                </Col>
            </Row>
    )
}

export default Footer;