import React, { useEffect, useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, Input, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, Form, Label, Spinner
} from 'reactstrap';

import { FaEdit, FaPlusCircle, FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const IndentList = () => {
  //redux (From indent)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {

    let list = [
      {
        "sl": 1,
        "Indent_No": 'TSEB1209',
        "Name": 'Engine Block',
        "Manufacturer": 'Tata Motors',
        "Quantity": '15',
        "action": <>
          <BsFillArrowRightSquareFill className='cursor' onClick={() => navigate('/IndentView')} size={25} color="#ea130e" />
        </>
      },
      {
        "sl": 2,
        "Indent_No": 'BFAF9832',
        "Name": 'Air Filter',
        "Manufacturer": 'Bharat Forge',
        "Quantity": '25',
        "action": <>
          <BsFillArrowRightSquareFill className='cursor' onClick={() => navigate('/IndentView')} size={25} color="#ea130e" />
        </>
      },
      {
        "sl": 3,
        "Indent_No": 'MHTG4532',
        "Name": 'Transmission Gearbox',
        "Manufacturer": 'Mahindra & Mahindra',
        "Quantity": '10',
        "action": <>
          <BsFillArrowRightSquareFill className='cursor' onClick={() => navigate('/IndentView')} size={25} color="#ea130e" />
        </>
      },
      {
        "sl": 4,
        "Indent_No": 'XYZH5029',
        "Name": 'Hydraulic Pump',
        "Manufacturer": 'Star Hydraulics',
        "Quantity": '20',
        "action": <>
          <BsFillArrowRightSquareFill className='cursor' onClick={() => navigate('/IndentView')} size={25} color="#ea130e" />
        </>
      }
    ]

    setTableData(list);
    setFilterData(list);
  }

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase().trim();
    const filteredData = tableData.filter(item =>
      item?.Name?.toLowerCase().includes(query) ||
      item?.Indent_No?.toLowerCase().includes(query) ||
      item?.Manufacturer?.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };


  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={6} className='mb-1'>
            <Title pageTitle="Indent List" />
          </Col>
          <Col md={3} className='text-end'>
            <Link to="/CreateIndent"><Button className='text-white' color='primary'><FaPlusCircle size={20} /> Create Indent </Button></Link>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Indent Date',
                      selector: row => row.Indent_No,
                      wrap: true
                    },
                    {
                      name: 'Indent No',
                      selector: row => row.Indent_No,
                      wrap: true
                    },
                    {
                      name: 'Created By',
                      selector: row => row.Name,
                    },
                    {
                      name: 'Indent Status',
                      selector: row => row.Manufacturer,
                      wrap: true,
                    },
                    {
                      name: 'Quantity',
                      selector: row => row.Quantity,
                      wrap: true
                    },
                    {
                      name: 'Action',
                      selector: row => row.action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page >
      <Footer />
    </div >
  )
}

export default IndentList;