import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button,
} from 'reactstrap';

import { FaFilePdf, FaEdit, FaClipboardList } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from 'react-icons/md';

const ProjectList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {

    let list = [
      {
        "sl": 1,
        "Project_Name": 'Railways Track Maintaintance ',
        "Project_Type": 'Construction',
        "Client_Name": 'vishwas upadhyay ',
        "Start_Date": '02-08-2024',
        "End_Date": '12-12-2025',
        "Project_Cost": '4025000',
        "Project_Status": 'Working',
        "action": <>
          <BsFillArrowRightSquareFill className='cursor' onClick={()=> navigate('/ProjectView')} size={25} color="#ea130e" />
        </>
      },
      {
        "sl": 2,
        "Project_Name": 'Road Maintaintance ',
        "Project_Type": 'Construction',
        "Client_Name": 'Aushtosh Kumar Yadav',
        "Start_Date": '25-08-2024',
        "End_Date": '10-11-2024',
        "Project_Cost": '120000',
        "Project_Status": 'Working',
        "action": <>
          <BsFillArrowRightSquareFill className='cursor' onClick={()=> navigate('/ProjectView')} size={25} color="#ea130e" />
        </>
      },
      
    ]
    setTableData(list);
  }

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Project List" />
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={3} className='mb-2'>
                    <Button className='text-white' color='primary' size="sm" onClick={get_list_handler} > <FaClipboardList size={20} />  Get Project List </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <DataTable
                      className='mb-3'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Project Name',
                          selector: row => row.Project_Name,
                          wrap: true
                        },
                        {
                          name: 'Project Type',
                          selector: row => row.Project_Type,
                          wrap: true
                        },
                        {
                          name: 'Client Name',
                          selector: row => row.Client_Name,
                          wrap: true,
                        },
                        {
                          name: 'Project Start Date',
                          selector: row => row.Start_Date,
                          wrap: true
                        },
                        {
                          name: 'Project End Date',
                          selector: row => row.End_Date,
                          wrap: true
                        },
                        {
                          name: 'Project Cost',
                          selector: row => row.Project_Cost,
                          wrap: true
                        },
                        {
                          name: 'Project Status',
                          selector: row => row.Project_Status,
                          wrap: true
                        },
                        {
                          name: 'Action',
                          selector: row => row.action,
                        },
                      ]}
                      data={tableData}
                      pagination
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>

      <Footer />
    </div>
  )
}

export default ProjectList;