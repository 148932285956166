import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button,
} from 'reactstrap';

import { FaFilePdf, FaEdit, FaClipboardList } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from 'react-icons/md';

const ManpowerList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {

    let list = [
      {
        "sl": 1,
        "Name": 'Ankit Kumar',
        "doj": '18 August 2020',
        "Mobile_No": '9890098900',
        "Project_Name": 'Railways',
        "Site_Name": 'Palamu',
        "Salary": '40000',
        "Skills": 'Site Manager',
        "action": <>
          <FaEdit className='cursor' size={20} color="#6da845" />
          <MdDeleteForever className="cursor ms-3" color="#ff4136" size={20} />
        </>
      },
      {
        "sl": 2,
        "Name": 'Kushal Kumar Jha',
        "doj": '02 Febuary 2011',
        "Mobile_No": '9890098900',
        "Project_Name": 'Railways',
        "Site_Name": 'Palamu',
        "Salary": '45000',
        "Skills": 'Site Manager',
        "action": <>
          <FaEdit className='cursor' size={20} color="#6da845" />
          <MdDeleteForever className="cursor ms-3" color="#ff4136" size={20} />
        </>
      },
      {
        "sl": 3,
        "Name": 'Rohit Banerjee',
        "doj": '05 March 2006',
        "Mobile_No": '9890098900',
        "Project_Name": 'Railways',
        "Site_Name": 'Palamu',
        "Salary": '55000',
        "Skills": 'Manager',
        "action": <>
          <FaEdit className='cursor' size={20} color="#6da845" />
          <MdDeleteForever className="cursor ms-3" color="#ff4136" size={20} />
        </>
      },
      {
        "sl": 4,
        "Name": 'Sonali Chandra',
        "doj": '08 December 2010',
        "Mobile_No": '9890098900',
        "Project_Name": 'Railways',
        "Site_Name": 'Palamu',
        "Salary": '55000',
        "Skills": 'Accountant',
        "action": <>
          <FaEdit className='cursor' size={20} color="#6da845" />
          <MdDeleteForever className="cursor ms-3" color="#ff4136" size={20} />
        </>
      },
    ]
    setTableData(list);
  }

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Manpower List" />
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={3} className='mb-2'>
                    <Button className='text-white' color='primary' size="sm" onClick={get_list_handler} > <FaClipboardList size={20} />  Get Manpower List </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <DataTable
                      className='mb-3'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Name',
                          selector: row => row.Name,
                        },
                        {
                          name: 'Date of Joining',
                          selector: row => row.doj,
                          wrap: true
                        },
                        {
                          name: 'Mobile No',
                          selector: row => row.Mobile_No,
                          wrap: true,
                        },
                        {
                          name: 'Project Name',
                          selector: row => row.Project_Name,
                          wrap: true
                        },
                        {
                          name: 'Site Name',
                          selector: row => row.Site_Name,
                          wrap: true
                        },
                        {
                          name: 'Skills',
                          selector: row => row.Skills,
                          wrap: true
                        },
                        {
                          name: 'Salary',
                          selector: row => row.Salary,
                          wrap: true
                        },
                        {
                          name: 'Action',
                          selector: row => row.action,
                        },
                      ]}
                      data={tableData}
                      pagination
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>

      <Footer />
    </div>
  )
}

export default ManpowerList;