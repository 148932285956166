import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './../component/layout/Header';
import Footer from './../component/layout/Footer';
import Page from '../component/layout/Page';

import axios from 'axios';
import { mainurl } from '../MainUrl';
import { notification } from '../component/hocs/notification';

import {
  Row, Col, Card, CardHeader, CardBody,
} from 'reactstrap';

import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

// bar chart data
const labels = ['Machine-1', 'Machine-2', 'Machine-3', 'Machine-4', 'Machine-5', 'Machine-6', 'Machine-7', 'Machine-8', 'Machine-9', 'Machine-10'];
const dataBar = {
  labels,
  datasets: [
    {
      label: 'Gross Machine',
      data: [80, 150, 220, 330, 150, 230, 80, 110, 300, 250],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(201, 203, 207, 0.7)'],
    },
  ],
};
const optionstack = {
  scales: {
    xAxes: [{
      stacked: true
    }],
    yAxes: [{
      stacked: true
    }]
  }
};
//
const optionsPie = {
  legend: {
    display: "abc",
    position: "bottom"
  },
  responsive: true,
  elements: {
    arc: {
      borderWidth: 0
    }
  },
};
const dataPie = {
  maintainAspectRatio: true,
  responsive: true,
  labels: ['Machine-1', 'Machine-2', 'Machine-3', 'Machine-4', 'Machine-5', 'Machine-6', 'Machine-7', 'Machine-8'],
  datasets: [
    {
      data: [45, 66, 89, 90, 180, 60, 55, 48],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(201, 203, 207, 0.7)'],
    }
  ]
};


const Home = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  return (
    <div>
      <Header />
      <Page>
        <Row >
          <Col xs={12} className="mb-3 " >
            <div className='d-flex justify-content-between '>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Total Machine</div>
                <div className='fs-20 text-primary'><b>150</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>In Maintenance</div>
                <div className='fs-20 text-primary'><b>15</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Overdue</div>
                <div className='fs-20 text-primary'><b>8</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Maintenance Pending </div>
                <div className='fs-20 text-primary'><b>20</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Average Machine Days</div>
                <div className='fs-20 text-primary'><b>8 days</b></div>
              </div>
            </div>
          </Col>

          <Col md={8}>
            <Card color="primary" outline >
              <CardHeader>Gross Machine </CardHeader>
              <CardBody>
                <Bar options={optionstack} data={dataBar} />
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card color="primary" outline >
              <CardHeader>Machine by Product Category</CardHeader>
              <CardBody>
                <Doughnut options={optionsPie} data={dataPie} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>

      <Footer />
    </div>
  )
}

export default Home;