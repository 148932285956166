import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginData: {
    login: false,
    userName: '',
    userId: '',
    logData:{},
    
  },
}

export const loggedSlice = createSlice({
  name: 'logged',
  initialState,
  reducers: {
    signIn: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.loginData = action.payload
    },
    signOut: (state, action) => {
      state.loginData = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { signIn, signOut } = loggedSlice.actions

export default loggedSlice.reducer