import React, { useEffect, useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, Input, InputGroupText, InputGroup
} from 'reactstrap';

import { FaEdit, FaSearch, FaCloudDownloadAlt } from 'react-icons/fa';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const StoreList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {

    let list = [
      {
        "sl": 1,
        "Date": '8 August 2024',
        "Ref": 'TSEB1209',
        "Item": 'Engine Block',
        "In": '12',
        "Out": '',
        "quantity": '5'
      },
      {
        "sl": 2,
        "Date": '17 August 2024',
        "Ref": 'BFAF9832',
        "Item": 'Hydraulics',
        "In": '',
        "Out": '8',
        "quantity": '8'
      },
      {
        "sl": 3,
        "Date": '23 August 2024',
        "Ref": 'MHTG4532',
        "Item": 'Air Filter',
        "In": '12',
        "Out": '',
        "quantity": '2'
      },
      {
        "sl": 4,
        "Date": '5 September 2024',
        "Ref": 'XYZH5029',
        "Item": 'Transmission',
        "In": '',
        "Out": '12',
        "quantity": '18'
      }
    ]

    setTableData(list);
    setFilterData(list);
  }

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase().trim();
    const filteredData = tableData.filter(item =>
      item?.Date?.toLowerCase().includes(query) ||
      item?.Ref?.toLowerCase().includes(query) ||
      item?.Item?.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };


  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={6} className='mb-1'>
            <Title pageTitle="Store Report" />
          </Col>
          <Col md={3} className='d-flex justify-content-end align-items-center'>
            <Input
              id="name"
              name="name"
              type="select"
              required
              className="me-2"
              style={{ width: '200px', fontSize: '13px' }}
            >
              <option value=""> Select Store Name </option>
            </Input>
            <Button className='text-white' color='primary'><FaCloudDownloadAlt size={20} /> Download </Button>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Date',
                      selector: row => row.Date,
                    },
                    {
                      name: 'Reference',
                      selector: row => row.Ref,
                      wrap: true
                    },
                    {
                      name: 'Part No',
                      selector: row => row.Item,
                      wrap: true,
                    },
                    {
                      name: 'Quantity (In)',
                      selector: row => row.In,
                      wrap: true
                    },
                    {
                      name: 'Quantity (Out)',
                      selector: row => row.Out,
                      wrap: true
                    },
                    {
                      name: 'Balance Quantity',
                      selector: row => row.quantity,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page >

      <Footer />
    </div >
  )
}

export default StoreList;