import * as React from 'react';

import {
  Row, Col, Button, Modal, ModalBody, ModalHeader
} from 'reactstrap';

import { confirmable, } from 'react-confirm';

const Confirmation = (props) => (
  <div className="static-modal">
    <Modal 
      centered
      scrollable
      size="md"
      isOpen={props.show}
      toggle={() => props.proceed(!props.show)}
    >
      <ModalHeader> {props.title || 'Please Confirm'} </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12 mb-3">
            {props.confirmation}
          </Col>
          <Col xs="12" className="text-end">
            <Button color="primary" className='text-white'  onClick={() => props.proceed(false)}>{props.cancelLabel || 'No'}</Button>
            <Button color="primary" className='text-white ms-4' onClick={() => props.proceed(true)}>{props.okLabel || 'Yes'}</Button>
          </Col>
        </Row>
        
      </ModalBody>
    </Modal>
  </div>
);

export default confirmable(Confirmation);
