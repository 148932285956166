import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../component/layout/Header";
import Footer from "../../component/layout/Footer";
import Page from "../../component/layout/Page";
import Title from "../../component/layout/Title";

import moment from "moment";
import axios from "axios";
import { mainurl, fileurl } from "../../MainUrl";
import { notification } from "../../component/hocs/notification";
import DataTable from "react-data-table-component";
import { custTableStyles } from "../../style/color";
import { confirm } from "../../component/utility/confirm";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";

import { FaFilePdf, FaEdit, FaClipboardList, FaSearch } from "react-icons/fa";
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from "react-icons/md";

const MachineList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [count, setCount] = useState({
    owned: 0,
    rented: 0,
    pollution: 0,
    insurance: 0,
    roadTax: 0,
    service: 0,
    oemWarranty: 0,
    underMaintenance: 0,
    idle: 0,
    running: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getDashboardCount();
    get_list_handler();
  }, []);

  const get_list_handler = () => {
    setLoading(true);

    const requestBody = {
      machine_status: 1,
      machine_type: 0,
      machine_alert: 0,
    };

    axios
      .post(mainurl + "machine_list", requestBody)
      .then(function (response) {
        console.log(response);
        let list = response.data.data.map((row, index) => {
          return {
            sl: index + 1,
            Reg_No: row?.registration_number ?? "",
            Model: row?.model_header?.model ?? "",
            Type: row?.machine_type ?? "",
            Owned_Rented: row?.machine_ownership_type?.ownership_type ?? "",
            Running_Hours: row?.running_hours ?? "",
            Location: row?.location ?? "",
            Status: row?.machine_status?.status ?? "",
            Action: (
              <>
                <BsFillArrowRightSquareFill
                  className="cursor"
                  onClick={() => navigate(`/MachineView/${row.machine_id}`)}
                  size={25}
                  color="#ea130e"
                />
              </>
            ),
          };
        });

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getDashboardCount = async () => {
    try {
      const response = await axios.get(mainurl + "machine_counts");
      console.log(response.data.data);
      const data = response.data.data.reduce((acc, item) => {
        if (item["Under Maintenance"]) acc.underMaintenance = Number(item["Under Maintenance"]);
        if (item["Running"]) acc.running = Number(item["Running"]);
        if (item["Idle"]) acc.idle = Number(item["Idle"]);
        if (item["Own"]) acc.owned = Number(item["Own"]);
        if (item["Rental"]) acc.rented = Number(item["Rental"]);
        return acc;
      }, {});
      setCount(data);
    } catch (error) {
      console.log("Error fetching counts:", error);
    }
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = tableData.filter(
      (item) =>
        item.Machine_No.toLowerCase().includes(query) ||
        item.Make.toLowerCase().includes(query) ||
        item.Model.toLowerCase().includes(query) ||
        item.Location.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Col xs={12}>
          <div className="d-flex justify-content-between">
            <div className="bBox">
              <div className="text-muted fs-bold">Owned Machine</div>
              <div className="fs-20 text-primary">
                <b>{count?.owned ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Rented Machine</div>
              <div className="fs-20 text-primary">
                <b>{count?.rented ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Pollution Expiring</div>
              <div className="fs-20 text-primary">
                <b>{count?.pollution ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Insurance Expiring</div>
              <div className="fs-20 text-primary">
                <b>{count?.insurance ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Road Tax Expiring</div>
              <div className="fs-20 text-primary">
                <b>{count?.roadTax ?? 0}</b>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} className="mb-3 ">
          <div className="d-flex justify-content-between">
            <div className="bBox">
              <div className="text-muted fs-bold">Service Expiry</div>
              <div className="fs-20 text-primary">
                <b>{count?.service ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">OEM Warranty Expiry</div>
              <div className="fs-20 text-primary">
                <b>{count?.oemWarranty ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Under Maintainence</div>
              <div className="fs-20 text-primary">
                <b>{count?.underMaintenance ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Idle Machines</div>
              <div className="fs-20 text-primary">
                <b>{count?.idle ?? 0}</b>
              </div>
            </div>
            <div className="bBox">
              <div className="text-muted fs-bold">Running Machines</div>
              <div className="fs-20 text-primary">
                <b>{count?.running ?? 0}</b>
              </div>
            </div>
          </div>
        </Col>
        <Row className="mb-3">
          <Col xs={9} className="mb-1">
            <Title pageTitle="Machine List" />
          </Col>
          <Col xs={3} className="mb-1 text-end">
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input
                size="sm"
                type="text"
                placeholder="Search"
                onChange={filter_data_handler}
              />
            </InputGroup>
          </Col>
        </Row>
        {/* <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={3} className='mb-2'>
                    <Button className='text-white' color='primary' size="sm" onClick={get_list_handler} > <FaClipboardList size={20} />  Get Machine List </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card> */}
        <Card color="primary" outline className="mb-3">
          <CardBody>
            <Row>
              <Col md={12} className="mb-2">
                <DataTable
                  className="mb-3"
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: "#",
                      selector: (row) => row.sl,
                      width: "50px",
                    },
                    {
                      name: "Reg No",
                      selector: (row) => row.Reg_No,
                    },
                    {
                      name: "Model",
                      selector: (row) => row.Model,
                      wrap: true,
                    },
                    {
                      name: "Type",
                      selector: (row) => row.Type,
                      wrap: true,
                    },
                    {
                      name: "Own/Rented",
                      selector: (row) => row.Owned_Rented,
                      wrap: true,
                    },
                    {
                      name: "Running Hours",
                      selector: (row) => row.Running_Hours,
                      wrap: true,
                    },
                    {
                      name: "Location",
                      selector: (row) => row.Location,
                      wrap: true,
                    },
                    {
                      name: "Status",
                      selector: (row) => row.Status,
                      wrap: true,
                    },
                    {
                      name: "Action",
                      selector: (row) => row.Action,
                    },
                  ]}
                  data={filterData}
                  pagination
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>

      <Footer />
    </div>
  );
};

export default MachineList;
