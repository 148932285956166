import React, { useEffect, useState } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, InputGroup, InputGroupText, Input
} from 'reactstrap';

import { FaFilePdf, FaEdit, FaClipboardList, FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from 'react-icons/md';

const RunningList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [selectedOption, setSelectedOption] = useState([]);

  const { machine_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {
    setLoading(true);

    const hardcodedData = [
      {
        reg_no: '123ABC',
        model_header: { manufacturer: 'Toyota', model: 'Corolla' },
        owner_type: 'Owned',
        location: 'New York',
        Running_id: 1,
      },
      {
        reg_no: '456DEF',
        model_header: { manufacturer: 'Honda', model: 'Civic' },
        owner_type: 'Rented',
        location: 'Los Angeles',
        Running_id: 2,
      },
      {
        reg_no: '789GHI',
        model_header: { manufacturer: 'Ford', model: 'Focus' },
        owner_type: 'Owned',
        location: 'Chicago',
        Running_id: 3,
      },
    ];

    let list = hardcodedData.map((row, index) => {
      return (
        {
          sl: index + 1,
          Running_No: row?.reg_no ?? '',
          Make: row?.model_header?.manufacturer ?? '',
          Model: row?.model_header?.model ?? '',
          Owned_Rented: row?.owner_type ?? '',
          Location: row?.location ?? '',
          Action:
            <>
              <BsFillArrowRightSquareFill className='cursor' onClick={() => navigate(`/RunningView/${row.Running_id}`)} size={25} color="#ea130e" />
            </>,
        }
      )
    })

    setTableData(list);
    setFilterData(list);
    setLoading(false);

  }

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = tableData.filter(item =>
      item.Running_No.toLowerCase().includes(query) ||
      item.Make.toLowerCase().includes(query) ||
      item.Model.toLowerCase().includes(query) ||
      item.Location.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={6} className='mb-1'>
            <Title pageTitle="Running Details" />
          </Col>
          <Col md={6} className='d-flex justify-content-end align-items-center'>
            <Input
              id="name"
              name="name"
              type="select"
              required
              className="me-2"
              style={{ width: '200px', fontSize: '15px' }}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option value="">Select Period</option>
              <option value="yearly">Yearly</option>
              <option value="monthly">Monthly</option>
              <option value="daily">Daily</option>
            </Input>
            {selectedOption === "yearly" && (
              <>
                <Input
                  id="fromYear"
                  name="fromYear"
                  type="number"
                  placeholder="From Year"
                  className="me-2"
                  style={{ width: '200px' }}
                />
                <Input
                  id="toYear"
                  name="toYear"
                  type="number"
                  placeholder="To Year"
                  style={{ width: '200px' }}
                />
              </>
            )}

            {selectedOption === "monthly" && (
              <>
                <Input
                  id="fromMonth"
                  name="fromMonth"
                  type="month"
                  className="me-2"
                  style={{ width: '200px' }}
                />
                <Input
                  id="toMonth"
                  name="toMonth"
                  type="month"
                  style={{ width: '200px' }}
                />
              </>
            )}

            {selectedOption === "daily" && (
              <>
                <Input
                  id="fromDate"
                  name="fromDate"
                  type="date"
                  className="me-2"
                  style={{ width: '200px' }}
                />
                <Input
                  id="toDate"
                  name="toDate"
                  type="date"
                  style={{ width: '200px' }}
                />
              </>
            )}
            <Button className='me-2 text-white' color='primary'>Submit </Button>
            {/* <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup> */}
          </Col>
        </Row>
        {/* <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={3} className='mb-2'>
                    <Button className='text-white' color='primary' size="sm" onClick={get_list_handler} > <FaClipboardList size={20} />  Get Running List </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card> */}
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Date',
                      selector: row => row.Model,
                      wrap: true,
                    },
                    {
                      name: 'Kilometer From',
                      selector: row => row.Make,
                      wrap: true
                    },
                    {
                      name: 'Kilometer To',
                      selector: row => row.Owned_Rented,
                      wrap: true
                    },
                    {
                      name: 'Kilometer Run',
                      selector: row => row.Owned_Rented,
                    },
                  ]}
                  data={filterData}
                  pagination
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>

      <Footer />
    </div>
  )
}

export default RunningList;