import React, { useEffect, useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, Input, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, Form, Label, Spinner, FormGroup
} from 'reactstrap';

import { FaEdit, FaPlusCircle, FaSearch, FaMinusCircle, FaFileAlt, FaCloudDownloadAlt } from 'react-icons/fa';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const StoreMaster = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [status, setStatus] = useState('');

  const [storeModal, setStoreModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {

    let list = [
      {
        "sl": 1,
        "Name": 'Engine Block',
        "Status": 'Active',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      },
      {
        "sl": 2,
        "Name": 'Air Filter',
        "Status": 'Active',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      },
      {
        "sl": 3,
        "Name": 'Transmission Gearbox',
        "Status": 'Active',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      },
      {
        "sl": 4,
        "Name": 'Hydraulic Pump',
        "Status": 'Active',
        "action": <>
          <FaEdit className='cursor ms-3' size={20} color="#6da845" />
        </>
      }
    ]

    setTableData(list);
    setFilterData(list);
  }

  const [itemsData, setItemsData] = useState([
    {
      name: 'Engine Block',
      status: 'Active',
    },
    {
      name: 'Air Filter',
      status: 'Active',
    },
    {
      name: 'Transmission Gearbox',
      status: 'Active',
    },
    {
      name: 'Hydraulic Pump',
      status: 'Active',
    },
  ]);

  const addItemToData = (itemName, status) => {
    const existingItem = itemsData.find(item => item.name === itemName);
    const newModel = { status };

    if (existingItem) {
      setItemsData(prevData =>
        prevData.map(item =>
          item.name === itemName
            ? { ...item, models: [...item.models, newModel] }
            : item
        )
      );
    } else {
      const newItem = {
        name: itemName,
        models: [newModel],
      };
      setItemsData(prevData => [...prevData, newItem]);
    }
  };

  const store_add_handler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      addItemToData(selectedItem, status);
      let notify = notification({ message: "Store Added Successfully", type: 'success' });
      notify();
      setStoreModal(false);
      get_list_handler();
    } catch (error) {
      let notify = notification({ message: "Failed! Try Again", type: 'error' });
      notify();
    } finally {
      setLoading(false);
    }
  };

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase().trim();
    const filteredData = tableData.filter(item =>
      item?.Name?.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={3} className='mb-1'>
            <Title pageTitle="Store Master" />
          </Col>
          <Col md={6} className='d-flex justify-content-end align-items-center'>
            <Button className='text-white' color='primary' onClick={() => setStoreModal(true)}><FaPlusCircle size={20} /> Add Item </Button>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Store Name',
                      selector: row => row.Name,
                    },
                    {
                      name: 'Status',
                      selector: row => row.Status,
                    },
                    {
                      name: 'Action',
                      selector: row => row.action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          centered
          scrollable
          size="lg"
          isOpen={storeModal}
          toggle={() => setStoreModal(!storeModal)}
        >
          <ModalHeader toggle={() => setStoreModal(!storeModal)}>
            Add Item
          </ModalHeader>
          <ModalBody className=''>
            <Form onSubmit={store_add_handler}>
              <Row>
                <Col xs={12} className='mb-2'>
                  <Label for="store_name">Store Name*</Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Enter Store Name"
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e.target.value)}
                    required
                  />
                </Col>
                {/* Status Input */}
                <Col xs={6} className='mb-2'>
                  <Label for="status">Status*</Label>
                  <Input
                    id="status"
                    placeholder="Enter Status"
                    type="text"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                  />
                </Col>

                <Col xs={12} className='mb-2'>
                  <b>Note:</b> (*) Mark fields are mandatory.
                </Col>

                <Col xs={12} className='mb-2 text-end'>
                  <Button className='text-white mt-2' block color='primary' disabled={loading}>
                    ADD {loading && <Spinner color="secondary">Loading...</Spinner>}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Page >

      <Footer />
    </div >
  )
}

export default StoreMaster;