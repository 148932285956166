import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import {
  Row, Col, Card, CardBody, Button, Label,
  CardHeader,
  CardTitle,
} from 'reactstrap';

import { FaPlus, FaEdit, FaClipboardList } from 'react-icons/fa';

// bar chart data
const labels = ['02-08-2024', '08-08-2024', '15-08-2024', '18-08-2024', '22-08-2024', '26-08-2024', '30-08-2024'];
const dataBar = {
  labels,
  datasets: [
    {
      label: 'Project Milestone',
      data: [0, 50, 60, 55, 80, 98, 80],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(201, 203, 207, 0.7)'],
    },
  ],
};

const optionstack = {
  scales: {
    xAxes: [{
      stacked: true
    }],
    yAxes: [{
      stacked: true
    }]
  }
};

const ProjectView = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);


  const [manpowerData, setManpowerData] = useState([]);
  const [machineData, setMachineData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    let list = [
      {
        "sl": 1,
        "Resource_Name": 'Rohit Banerjee',
        "Resource_Type": 'Manager',
        "Cost": 'Rs. 2200.00',
        "working_Days": '10',
        "Total_Cost": 'Rs. 22000.00',
        "action": <><FaEdit className='cursor' size={20} color="#6da845" /></>
      },
      {
        "sl": 2,
        "Resource_Name": 'Kushal Kumar Jha',
        "Resource_Type": 'Site Manager',
        "Cost": 'Rs. 1800.00',
        "working_Days": '15',
        "Total_Cost": 'Rs. 27000.00',
        "action": <><FaEdit className='cursor' size={20} color="#6da845" /></>
      },
      {
        "sl": 3,
        "Resource_Name": 'Ankit Kumar',
        "Resource_Type": 'Site Manager',
        "Cost": 'Rs. 1600.00',
        "working_Days": '15',
        "Total_Cost": 'Rs. 24000.00',
        "action": <><FaEdit className='cursor' size={20} color="#6da845" /></>
      },
      {
        "sl": 4,
        "Resource_Name": 'Sonali Chandra',
        "Resource_Type": 'Accountant',
        "Cost": 'Rs. 2200.00',
        "working_Days": '10',
        "Total_Cost": 'Rs. 22000.00',
        "action": <><FaEdit className='cursor' size={20} color="#6da845" /></>
      },
    ];
    setManpowerData(list);

    let list2 = [
      {
        "sl": 1,
        "Machine_No": 'USBE5230',
        "Cost": 'Rs. 1200.00',
        "Working_Day": '10',
        "Total_Cost": 'Rs. 12000.00',
        "action": <><FaEdit className='cursor' size={20} color="#6da845" /></>
      },
      {
        "sl": 2,
        "Machine_No": 'KHSD6620',
        "Cost": 'Rs. 1500.00',
        "Working_Day": '15',
        "Total_Cost": 'Rs. 22500.00',
        "action": <><FaEdit className='cursor' size={20} color="#6da845" /></>
      },
      {
        "sl": 3,
        "Machine_No": 'FFKW8751',
        "Cost": 'Rs. 800.00',
        "Working_Day": '20',
        "Total_Cost": 'Rs. 16000.00',
        "action": <><FaEdit className='cursor' size={20} color="#6da845" /></>
      },
    ]
    setMachineData(list2);

  }, []);

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Project Details View" />
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardHeader>Project Information</CardHeader>
              <CardBody>
                <Row>
                  <Label sm={3} > <b>Project Name: </b> </Label>
                  <Label sm={3} > Railways Track Maintaintance </Label>

                  <Label sm={3} > <b>Project Type:</b> </Label>
                  <Label sm={3} > Construction </Label>

                  <Label sm={3} > <b>Client Name:</b> </Label>
                  <Label sm={3} > vishwas upadhyay </Label>

                  <Label sm={3} > <b>Start Date: </b> </Label>
                  <Label sm={3} > 02 August 2024</Label>

                  <Label sm={3} > <b>End Date: </b> </Label>
                  <Label sm={3} >  12 December 2025 </Label>

                  <Label sm={3} > <b>Project Cost: </b> </Label>
                  <Label sm={3} > Rs. 4025000.00 </Label>

                  <Label sm={3} > <b>Project Duration: </b> </Label>
                  <Label sm={3} > 2 Years </Label>

                  <Label sm={3} > <b>Project Status: </b> </Label>
                  <Label sm={3} > Working </Label>

                </Row>
              </CardBody>
            </Card>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Manpower Information</CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                  <DataTable
                      className='mb-3'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Resource Name',
                          selector: row => row.Resource_Name,
                          wrap: true
                        },
                        {
                          name: 'Resource Type',
                          selector: row => row.Resource_Type,
                          wrap: true
                        },
                        {
                          name: 'Cost / Day',
                          selector: row => row.Cost,
                          wrap: true,
                        },
                        {
                          name: 'Working Days',
                          selector: row => row.working_Days,
                          wrap: true,
                        },
                        {
                          name: 'Total Cost',
                          selector: row => row.Total_Cost,
                          wrap: true
                        },
                        {
                          name: 'Action',
                          selector: row => row.action,
                        },
                      ]}
                      data={manpowerData}
                      pagination
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Equipment Information</CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                  <DataTable
                      className='mb-3'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Machine Number',
                          selector: row => row.Machine_No,
                          wrap: true
                        },
                        {
                          name: 'Cost / Day',
                          selector: row => row.Cost,
                          wrap: true
                        },
                        {
                          name: 'Working Days',
                          selector: row => row.Working_Day,
                          wrap: true,
                        },
                        {
                          name: 'Total Cost',
                          selector: row => row.Total_Cost,
                          wrap: true
                        },
                        {
                          name: 'Action',
                          selector: row => row.action,
                        },
                      ]}
                      data={machineData}
                      pagination
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Project Milestone</CardHeader>
              <CardBody>
                <Row>
                  <Col md={{
                    offset: 3,
                    size: 6
                  }} className='mb-2'>
                    <Line options={optionstack} data={dataBar} />
                  </Col>
                </Row>
              </CardBody>
            </Card>


          </Col>

          
        </Row>
      </Page>

      <Footer />
    </div>
  )
}

export default ProjectView;