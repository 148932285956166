import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter } from "react-router-dom";
import { Provider } from 'react-redux';

import { persistor, store } from './component/redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config';
import { MsalProvider } from '@azure/msal-react';


const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </PersistGate>
    </Provider>
  </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
